import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.use(Vuetify);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0b3363',
            },
        },
    },
});
